import {
  SecureInputsGlobalPostMessageEventKind,
  secureInputFields,
  type Maybe,
  type SecureInputsConfig,
  type SecureInputsFields,
  type SecureInputsFrameConfig,
  type SecureInputsPostMessageEvent,
  type SecureInputsRenderedFields,
  type SecureInputsSubmitError,
} from "../../types";

import { buildFrame } from "./buildFrame";
import { iframeOrigin, generateUuid } from "./config";
import { handlePostMessage } from "./handlePostMessage";

interface Field {
  container: Element;
  frame: HTMLIFrameElement;
}
type FieldsMap = Readonly<Record<SecureInputsFields, Maybe<Field>>>;

// eslint-disable-next-line @typescript-eslint/require-await, putout/putout
const unmount = async (fields: Readonly<FieldsMap>): Promise<void> => {
  Object.values(fields).forEach((field) => {
    if (field) {
      field.container.replaceChildren("");
    }
  });
};

export const renderFields = async ({
  onSuccess,
  onInput,
  onError,
  elements,
  environment = "test",
}: // eslint-disable-next-line @typescript-eslint/require-await
Readonly<SecureInputsConfig>): Promise<SecureInputsRenderedFields> => {
  const uuid = generateUuid();
  const fields = secureInputFields.reduce<FieldsMap>(
    (accum, field): FieldsMap => {
      const elementConfig = elements[field];
      const { selector } = elementConfig;
      const container = document.querySelector(selector) ?? document.body;
      const buildFrameConfig: SecureInputsFrameConfig = {
        uuid,
        field,
        environment,
        elementConfig,
      };
      const frame = buildFrame(buildFrameConfig);

      container.append(frame);

      return {
        ...accum,

        [field]: {
          container,
          frame,
        },
      };
    },
    {
      pin: undefined,
    }
  );

  window.removeEventListener(
    "message",
    handlePostMessage({ uuid, onError, onInput, onSuccess })
  );
  window.addEventListener(
    "message",
    handlePostMessage({ uuid, onError, onInput, onSuccess })
  );

  return {
    submit: (): Maybe<SecureInputsSubmitError> => {
      const targetWindow = fields.pin?.frame.contentWindow;

      if (targetWindow === null || targetWindow === undefined) {
        return {
          name: "SecureInputsSubmitError",
          message: "Unable to submit field: pin",
        };
      }

      const event: SecureInputsPostMessageEvent = {
        __typename: SecureInputsGlobalPostMessageEventKind.ON_SUBMIT,
      };

      targetWindow.postMessage(event, iframeOrigin);

      return undefined;
    },

    unmount: async () => {
      await unmount(fields);
    },
  };
};
