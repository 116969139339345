import {
  SecureInputsGlobalPostMessageEventKind,
  type HighnotePostMessageEvent,
  type SecureInputsErrorCallback,
  type SecureInputsFieldInputError,
  type SecureInputsGlobalPostMessageEvent,
  type SecureInputsInputCallback,
  type SecureInputsSuccessCallback,
} from "../../types";

import { iframeOrigin } from "./config";

const FALLBACK_ERROR_MESSAGE =
  "Something went wrong. Please try again or contact support.";

const handleUncaughtError = (onError: SecureInputsErrorCallback) => {
  onError({
    name: "Error",
    message: FALLBACK_ERROR_MESSAGE,
  });
};

interface HandlePostMessageParameters {
  uuid: string;
  onError: SecureInputsErrorCallback;
  onInput: SecureInputsInputCallback;
  onSuccess: SecureInputsSuccessCallback;
}

export const handlePostMessage =
  ({ uuid, onError, onInput, onSuccess }: HandlePostMessageParameters) =>
  // eslint-disable-next-line max-statements
  (
    event: MessageEvent<
      HighnotePostMessageEvent<SecureInputsGlobalPostMessageEvent>
    >
  ): boolean => {
    if (event.origin !== iframeOrigin || uuid !== event.data.uuid) {
      return false;
    }

    const { data } = event.data;

    switch (data.__typename) {
      case SecureInputsGlobalPostMessageEventKind.INVALID_CREDENTIAL_ERROR:
        onError({
          name: "InvalidCredentialError",
          context: data.context,
        });
        break;
      case SecureInputsGlobalPostMessageEventKind.ON_SUCCESS:
        onSuccess(data.field);
        break;
      case SecureInputsGlobalPostMessageEventKind.ON_INPUT:
        onInput(data.field, data.config);
        break;
      case SecureInputsGlobalPostMessageEventKind.USER_INPUT_ERROR: {
        const { field, message } = data;
        const error: SecureInputsFieldInputError = {
          name: "SecureInputsFieldInputError",
          field,
          message,
        };
        onError(error);
        break;
      }
      case SecureInputsGlobalPostMessageEventKind.REQUEST_ERROR: {
        const { field, message, context } = data;
        onError({
          name: "SecureInputsRequestError",
          field,
          message,
          context,
        });
        break;
      }
      case SecureInputsGlobalPostMessageEventKind.GENERIC_ERROR:
        onError({
          name: "Error",
          message: data.message,
        });
        break;
      default:
        handleUncaughtError(onError);
        break;
    }

    return true;
  };
