{
  "name": "@highnoteplatform/secure-inputs",
  "version": "1.0.4",
  "description": "Secure input fields for your customer.",
  "source": "src/index.ts",
  "access": "public",
  "main": "lib/index.js",
  "module": "lib/esm/module.js",
  "types": "lib/types.d.ts",
  "files": [
    "lib",
    "README.md"
  ],
  "scripts": {
    "clean": "rimraf lib dist",
    "prebuild": "npm run clean",
    "build": "PARCEL_WORKER_BACKEND=process parcel build --detailed-report --no-scope-hoist --no-cache",
    "prepublishOnly": "npm run build",
    "test": "jest",
    "test:watch": "jest --watch",
    "prepack": "npm run build",
    "predev": "npm run clean",
    "dev": "parcel watch --port 3005 --hmr-port 2005 --no-cache --dist-dir=lib"
  },
  "author": "packages@highnote.com",
  "license": "MIT",
  "homepage": "https://highnote.com",
  "devDependencies": {
    "@types/jest": "28.1.8",
    "@types/jsdom": "21.1.1",
    "@types/node": "18.16.2",
    "eslint-plugin-jest": "27.2.1",
    "jest": "^28.1.3",
    "jest-environment-jsdom": "^29.5.0",
    "jsdom": "21.1.1",
    "ts-jest": "28.0.8"
  },
  "targets": {
    "main": {
      "optimize": true
    },
    "module": {
      "optimize": true
    }
  },
  "dependencies": {
    "@types/uuid": "^9.0.1",
    "uuid": "^9.0.0"
  }
}
