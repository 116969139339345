import { v4 as randomUUID } from "uuid";

import { version } from "../package.json";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const { CARD_VIEWER_IFRAME_PORT = 3003 } = process.env;

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const packageVersion = version ?? "";

export const iframeOrigin =
  // eslint-disable-next-line unicorn/consistent-destructuring
  process.env.NODE_ENV === "production"
    ? "https://cdn.highnote.com"
    : `http://localhost:${CARD_VIEWER_IFRAME_PORT}`;

const iframePath =
  // eslint-disable-next-line unicorn/consistent-destructuring
  process.env.NODE_ENV === "production"
    ? `card-viewer/${packageVersion}/index.html`
    : "index.html";

export const generateUuid = () => randomUUID();

export const iframeUri = `${iframeOrigin}/${iframePath}`;
