import type {
  GraphQLExtensions,
  AllowedFrameStyle,
  ClientToken,
  GenericError,
  HighnoteEnvironment,
  InvalidCredentialError,
  PaymentCardId,
} from ".";

export const secureInputFields = ["pin"] as const;
export type SecureInputsFields = (typeof secureInputFields)[number];

export interface SecureInputsElementConfig {
  // A valid [DOMString](https://developer.mozilla.org/en-US/docs/Web/API/DOMString) that is a valid CSS Selector.
  //
  // Examples: "#foo", ".bar", '[data-foo="bar"]'
  //
  selector: string;
  clientToken: ClientToken;
  paymentCardId: PaymentCardId;
  styles?: AllowedFrameStyle;
}

export enum SecureInputsGlobalPostMessageEventKind {
  GENERIC_ERROR = "GENERIC_ERROR",
  INVALID_CREDENTIAL_ERROR = "INVALID_CREDENTIAL_ERROR",
  USER_INPUT_ERROR = "USER_INPUT_ERROR",
  REQUEST_ERROR = "REQUEST_ERROR",
  ON_INPUT = "ON_INPUT",
  ON_SUBMIT = "ON_SUBMIT",
  ON_SUCCESS = "ON_SUCCESS",
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions, etc/prefer-interface
export type SecureInputsGlobalGraphQLPostMessageEvent = {
  __typename: SecureInputsGlobalPostMessageEventKind.INVALID_CREDENTIAL_ERROR;
  context: GraphQLExtensions;
};

export type SecureInputsPostMessageEvent =
  | {
      __typename: SecureInputsGlobalPostMessageEventKind.GENERIC_ERROR;
      message: string;
    }
  | {
      __typename: SecureInputsGlobalPostMessageEventKind.ON_INPUT;
      field: SecureInputsFields;
      config: SecureInputsElementConfig;
    }
  | {
      __typename: SecureInputsGlobalPostMessageEventKind.ON_SUBMIT;
    }
  | {
      __typename: SecureInputsGlobalPostMessageEventKind.ON_SUCCESS;
      field: SecureInputsFields;
    }
  | {
      __typename: SecureInputsGlobalPostMessageEventKind.REQUEST_ERROR;
      field: SecureInputsFields;
      message: string;
      context: GraphQLExtensions;
    }
  | {
      __typename: SecureInputsGlobalPostMessageEventKind.USER_INPUT_ERROR;
      field: SecureInputsFields;
      message: string;
    };

// Construct for postMessage events meant to be sent across origins.
export type SecureInputsGlobalPostMessageEvent =
  | SecureInputsGlobalGraphQLPostMessageEvent
  | SecureInputsPostMessageEvent;

export type SecureInputsFieldInputError = Readonly<{
  name: "SecureInputsFieldInputError";
  field: SecureInputsFields;
  message: string;
}>;

export type SecureInputsRequestError = Readonly<{
  name: "SecureInputsRequestError";
  field: SecureInputsFields;
  message?: string;
  context: GraphQLExtensions;
}>;

// A generic catch-all error. Typically, this captures exceptions that are not recoverable.
export type SecureInputsError = Readonly<
  | GenericError
  | InvalidCredentialError
  | SecureInputsFieldInputError
  | SecureInputsRequestError
>;

export type SecureInputsConfigError = Readonly<SecureInputsError>;

// eslint-disable-next-line etc/prefer-interface
export type SecureInputsErrorCallback = (error: SecureInputsError) => void;
// eslint-disable-next-line etc/prefer-interface
export type SecureInputsSuccessCallback = (field: SecureInputsFields) => void;
// eslint-disable-next-line etc/prefer-interface
export type SecureInputsInputCallback = (
  field: SecureInputsFields,
  config: SecureInputsElementConfig
) => void;

// An error that occurs when unable to submit fields
export type SecureInputsSubmitError = Readonly<{
  name: "SecureInputsSubmitError";
  message?: string;
}>;

export interface SecureInputsConfig {
  elements: Record<SecureInputsFields, SecureInputsElementConfig>;
  onError: SecureInputsErrorCallback;
  onSuccess: SecureInputsSuccessCallback;
  onInput: SecureInputsInputCallback;
  // eslint-disable-next-line multiline-comment-style
  /** The environment to use for making requests to the Highnote API.
   *
   * default value: 'test'
   */
  environment?: HighnoteEnvironment;
}

export interface SecureInputsRenderedFields {
  // Submits all input fields.
  submit: () => void;
  // Unmount all of the embedded iframes.
  unmount: () => Promise<void>;
}

export interface SecureInputsFrameConfig {
  uuid: string;
  elementConfig: SecureInputsElementConfig;
  field: SecureInputsFields;
  environment: HighnoteEnvironment;
}
